import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    selectedItem: [],
    drawerOpen: false,
    drawerType: 'temporary'
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.selectedItem = action.payload;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },

        // onHover(state, action) {
        //     if (state.drawerType !== 'permanent') {
        //         state.drawerOpen = action.payload;
        //     }
        // },

        changeDrawerType(state, action) {
            state.drawerType = action.payload;
        }
    }
});

export default menu.reducer;

export const { activeItem, openDrawer, changeDrawerType } = menu.actions;
