import { Search } from '@mui/icons-material';
import { Avatar, Box, Collapse, TextField, Tooltip } from '@mui/material';
import { useTheme } from '@mui/styles';
import AsyncDropdown from 'components/AsyncDropdown';
import useAuth from 'hooks/useAuth';
import usePermission from 'hooks/usePermission';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetPatientListQuery } from 'store/services/patient';
import { encriptId } from 'utils/encription';

export default function PatientSearchBox() {
    const { user } = useAuth();
    const { hasPermission } = usePermission();
    const theme = useTheme();
    const navigate = useNavigate();
    const ref = useRef(null);
    const inputRef = useRef(null);

    const [val, setVal] = useState(null);

    const [expanded, setExpanded] = useState(false);

    const handleChange = (e) => {
        setVal(e);
        if (e?.id) {
            navigate(`/edit-patient/${encriptId(e.id)}`);
        }
    };

    useEffect(() => {
        function handleClickOutside(e) {
            if (ref.current && !ref.current.contains(e.target)) {
                setExpanded(false);
                setTimeout(() => {
                    setVal(null);
                }, 100);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [ref]);

    useEffect(() => {
        if (expanded && inputRef?.current) {
            setTimeout(() => {
                inputRef?.current?.focus();
            }, 400);
        }
    }, [inputRef, expanded]);

    if (!hasPermission(['patient_edit'])) {
        return null;
    }

    return (
        <Box
            ref={ref}
            sx={{
                mt: -0.8,
                ml: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 1
            }}
        >
            {!expanded && (
                <Tooltip title="Search for patient">
                    <Avatar
                        className="nav-menu"
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                            }
                        }}
                        onClick={() => setExpanded(true)}
                        color="inherit"
                    >
                        <Search stroke={1.5} size="20px" />
                    </Avatar>
                </Tooltip>
            )}
            <Collapse in={expanded} orientation="horizontal">
                <Box sx={{ minWidth: 200 }}>
                    <AsyncDropdown
                        value={val}
                        size="small"
                        name="patient"
                        SearchAccessor="name"
                        handler={useGetPatientListQuery}
                        onChange={handleChange}
                        clearOnBlur
                        getOptionLabel={(option) => option.user?.name}
                        renderOption={(props, option) => <li {...props}>{option.user?.name}</li>}
                        payload={{
                            payload: {
                                filters: {
                                    ...(user?.client_id && { client_id: user.client_id }),
                                    status: 1,
                                    order_col: 'name',
                                    order: 'asc'
                                },
                                pagination: { current_page: 1 }
                            },
                            skip: !user?.client_id
                        }}
                        renderInput={(props) => (
                            <form autoComplete="new-password">
                                <TextField
                                    placeholder="Search by patient."
                                    {...props}
                                    inputRef={inputRef}
                                    sx={{
                                        mt: 0.2,
                                        '& .MuiInputBase-root': {
                                            p: '3px !important'
                                        }
                                    }}
                                />
                            </form>
                        )}
                        filterOptions={(options, value) => {
                            const { inputValue, getOptionLabel } = value;
                            const searchValue = inputValue.toLowerCase().trim();
                            const filteredList = options.filter((option) =>
                                getOptionLabel(option).toLowerCase().trim().includes(searchValue)
                            );

                            return filteredList;
                        }}
                    />
                </Box>
            </Collapse>
        </Box>
    );
}
