import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import SendErrorLog from './SendErrorLog';

import * as StackTrace from 'stacktrace-js';
// eslint-disable-next-line import/no-extraneous-dependencies
import sourceMap from 'source-map';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorInfo: null, renderCounter: 0 };
        this.resetState = this.resetState.bind(this);
        this.sourceMapCache = new Map(); // Cache for loaded source maps
    }

    // static getDerivedStateFromError(error) {
    //     // Update state so the next render will show the fallback UI.
    //     return { hasError: true };
    // }

    async componentDidCatch(error, errorInfo) {
        console.log('Error Boundary', { error, errorInfo });

        // Reload the page if a chunk load error occurs
        const isChunkLoadError = /ChunkLoadError/i.test(error?.stack);
        if (isChunkLoadError) {
            window.location.reload();
        } else {
            const { frames, bundleFiles } = await this.getOriginalStackTrace(error);
            console.log({ frames, bundleFiles });

            this.setState((prevState) => ({
                ...prevState,
                hasError: true,
                renderCounter: prevState.renderCounter + 1,

                errorInfo: {
                    timestamp: new Date().toISOString(),
                    error: {
                        message: error.message,
                        name: error.name
                    },
                    stackTrace: {
                        mappedStack: frames,
                        bundleFiles
                    },
                    errorInfo,
                    userAgent: navigator.userAgent,
                    url: window.location.href,
                    performanceMetrics: {
                        memory: performance.memory && {
                            usedJSHeapSize: performance.memory.usedJSHeapSize,
                            totalJSHeapSize: performance.memory.totalJSHeapSize
                        }
                    }
                }
            }));
        }
    }

    // Clean up cached source maps
    componentWillUnmount() {
        // eslint-disable-next-line no-restricted-syntax
        for (const consumer of this.sourceMapCache.values()) {
            consumer.destroy();
        }
        this.sourceMapCache.clear();
    }

    // eslint-disable-next-line class-methods-use-this, react/sort-comp
    getBundleFiles(frames) {
        return Array.from(
            new Set(
                frames
                    .map((frame) => frame.fileName)
                    .filter((fileName) => fileName && fileName.startsWith(window.location.origin) && fileName.endsWith('.js'))
            )
        );
    }

    async getOriginalStackTrace(error) {
        try {
            // Get stack frames
            const frames = await StackTrace.fromError(error);

            // Get unique bundle files
            const bundleFiles = this.getBundleFiles(frames);

            // Load all required source maps
            const sourceMapConsumers = new Map();

            await Promise.all(
                bundleFiles.map(async (bundleUrl) => {
                    const consumer = await this.loadSourceMap(bundleUrl);
                    if (consumer) {
                        sourceMapConsumers.set(bundleUrl, consumer);
                    }
                })
            );

            // Map each frame to original source
            const mappedFrames = await Promise.all(
                frames.map(async (frame) => {
                    const consumer = sourceMapConsumers.get(frame.fileName);

                    if (!consumer) {
                        return frame; // Return unmapped frame if no source map
                    }

                    const original = consumer.originalPositionFor({
                        line: frame.lineNumber,
                        column: frame.columnNumber
                    });

                    return {
                        functionName: original.name || frame.functionName,
                        fileName: original.source || frame.fileName,
                        lineNumber: original.line || frame.lineNumber,
                        columnNumber: original.column || frame.columnNumber,
                        source: frame.fileName // Keep original bundle filename for reference
                    };
                })
            );

            return {
                frames: mappedFrames,
                bundleFiles
            };
        } catch (mappingError) {
            console.error('Error mapping stack trace:', mappingError);
            return {
                frames: error.stack,
                bundleFiles: []
            };
        }
    }

    // Load source map with caching
    async loadSourceMap(bundleUrl) {
        if (this.sourceMapCache.has(bundleUrl)) {
            return this.sourceMapCache.get(bundleUrl);
        }

        try {
            const response = await fetch(`${bundleUrl}.map`);
            if (!response.ok) {
                throw new Error(`Failed to load source map for ${bundleUrl}`);
            }
            const sourceMapData = await response.json();
            const consumer = await new sourceMap.SourceMapConsumer(sourceMapData);

            // Cache the consumer
            this.sourceMapCache.set(bundleUrl, consumer);
            return consumer;
        } catch (error) {
            console.error(`Error loading source map for ${bundleUrl}:`, error);
            return null;
        }
    }

    resetState() {
        this.setState({ hasError: false, errorInfo: null, renderCounter: 0 });
    }

    render() {
        const { hasError, errorInfo, renderCounter } = this.state;
        const { children } = this.props;

        const canRenderChild = renderCounter < 2;

        if (hasError) {
            // Render fallback UI

            return (
                <>
                    {canRenderChild && children}
                    <ErrorDialog canRenderChild={canRenderChild} resetError={this.resetState} />
                    <SendErrorLog hasError={hasError} errorInfo={errorInfo} renderCounter={renderCounter} />
                </>
            );
        }

        // Render children if no errors
        return children;
    }
}

export default ErrorBoundary;

function ErrorDialog({ canRenderChild, resetError }) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        resetError();
    };

    const returnToLastPage = () => {
        navigate(-1);
        resetError();
    };

    return (
        <Dialog
            open={open}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">An Unexpected Error Occurred</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">Sorry for the inconvenience. Please try again later.</DialogContentText>
            </DialogContent>
            <DialogActions>
                {canRenderChild ? (
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                ) : (
                    <Button onClick={returnToLastPage} autoFocus>
                        Return to Last Page
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
