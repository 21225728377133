import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Divider, IconButton, InputAdornment, List, TextField, Typography } from '@mui/material';

// project imports
import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';

import { useSelector } from 'store';
import usePermission from 'hooks/usePermission';
import useAuth from 'hooks/useAuth';
import { PHYSICIAN_CLIENT } from 'constant';
import { Search } from '@mui/icons-material';
import { AspyreTextField } from 'components/common/InputFields';
import { useLocation } from 'react-router-dom';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const { user } = useAuth();
    const theme = useTheme();
    const { pathname } = useLocation();

    const { drawerOpen, selectedItem } = useSelector((state) => state.menu);
    const { hasPermission, isSuperAdmin, hasServiceAvailable } = usePermission();

    const hasAccess = (item) =>
        hasPermission(item.permissions) &&
        (item?.isService && !isSuperAdmin ? hasServiceAvailable(item?.services) : true) &&
        (user.is_superuser && user?.client_id === PHYSICIAN_CLIENT ? ['dashboard', 'settings', 'mail'].includes(item.id) : true);

    const [currentItem, setCurrentItem] = useState(item);
    const [searchText, setSearchText] = useState('');

    function filterMenuByTitle(menuList, searchText) {
        console.log({ menuList, searchText });

        // Convert searchText to lowercase for case insensitive search
        const lowerSearchText = searchText.toLowerCase();

        function filterItems(item) {
            // If the item or any of its children match the search, return the item
            if (item.children) {
                // Filter the children recursively
                const filteredChildren = item.children.map(filterItems).filter(Boolean);

                if (filteredChildren.length > 0) {
                    // If there are any matched children, include the current item and the filtered children
                    return {
                        ...item,
                        children: filteredChildren
                    };
                }
            }

            // Check if the current item's title includes the search text
            if (item.searchKey?.toLowerCase().includes(lowerSearchText) && hasAccess(item)) {
                return item;
            }

            // No match found
            return null;
        }

        // Iterate through the menu list and filter items
        return {
            ...menuList,
            children: menuList.children.map(filterItems).filter(Boolean)
        };
    }

    useEffect(() => {
        if (searchText) {
            setCurrentItem(filterMenuByTitle(item, searchText));
        } else {
            setCurrentItem(item);
        }
    }, [searchText, item]);

    useEffect(() => {
        setSearchText('');
    }, [pathname]);

    const items = currentItem.children?.filter(hasAccess).map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} parentId={currentItem.id} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} parentId={currentItem.id} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <Box
                sx={{
                    mt: 2,
                    width: '100%',
                    display: 'grid',
                    alignItems: 'center'
                }}
            >
                {drawerOpen ? (
                    <AspyreTextField
                        sx={{
                            mt: 1,
                            '& .MuiInputBase-input': {
                                height: 24,
                                fontSize: 16,
                                fontWeight: 500
                            }
                        }}
                        variant="standard"
                        type="search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search color="primary" />
                                </InputAdornment>
                            )
                        }}
                        debounceTime={250}
                        autoFocus
                    />
                ) : (
                    <>
                        <IconButton size="large">
                            <Search fontSize="18px" />
                        </IconButton>
                    </>
                )}
            </Box>

            <Divider sx={{ mb: 2 }} />

            <List
                disablePadding={!drawerOpen}
                subheader={
                    currentItem.title &&
                    drawerOpen && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {currentItem.title}
                            {currentItem.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {currentItem.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            {/* {drawerOpen && <Divider sx={{ mt: 0.25, mb: 1.25 }} />} */}
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
