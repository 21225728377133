// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import LAYOUT_CONST, { CLIENT_SWITCH_EXCLUDE } from 'constant';
import useConfig from 'hooks/useConfig';
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';

import { useDispatch, useSelector } from 'store';
import { changeDrawerType, openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';
import aspyrelogo from '../../../assets/images/aspyre-white-transparent.png';
import useAuth from 'hooks/useAuth';
import Notification from 'components/Notification';
import ClientSwitcher from 'components/ClientSwitcher';
import ClientLogo from 'components/ClientLogo';
import { useParams, useNavigate } from 'react-router-dom';
import PatientSearchBox from 'components/PatientSearch';

const Header = () => {
    const theme = useTheme();
    const { user } = useAuth();
    const params = useParams();
    const dispatch = useDispatch();
    const { drawerOpen, drawerType } = useSelector((state) => state.menu);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();
    const navigate = useNavigate();

    const showClientSwitcher = user?.is_superuser && CLIENT_SWITCH_EXCLUDE.every((i) => !Object.keys(params).includes(i));

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box
                    component="span"
                    sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <img src={aspyrelogo} alt="Logo" width="75%" height="auto" className="header-logo" style={{ cursor: 'pointer' }} />
                </Box>

                {layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
                    <Avatar
                        className="nav-menu"
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            marginTop: '0px !important',
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                            }
                        }}
                        onClick={() => {
                            dispatch(openDrawer(!drawerOpen));
                            dispatch(changeDrawerType(drawerType === 'permanent' ? 'temporary' : 'permanent'));
                        }}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="20px" />
                    </Avatar>
                ) : null}
            </Box>
            {/* header search */}
            <SearchSection />
            <PatientSearchBox />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" alignItems="center" spacing={1} sx={{ mr: 1 }}>
                <ClientLogo />
                {showClientSwitcher && <ClientSwitcher />}
                <Notification />
            </Stack>
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
