import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useIdleTimer } from 'react-idle-timer';
import { IDLE_TIMEOUT_DURATION } from 'constant';
import useAuth from 'hooks/useAuth';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const TIMEOUT = IDLE_TIMEOUT_DURATION * 60 * 1000;
const PROMPT_BEFORE_IDLE = IDLE_TIMEOUT_DURATION > 0 ? 30_000 : -1;
const THROTTLE = 1000;
const SYNC_TIMERS = 1000;

function IdleDetector({ children }) {
    const [remaining, setRemaining] = useState(TIMEOUT);
    const [open, setOpen] = useState(true);

    const { isLoggedIn, logoutAndReset } = useAuth();

    const onIdle = () => {
        console.log('User is idle');
        logoutAndReset();
        setOpen(false);
    };

    const onActive = () => {
        console.log('User is active again');
    };

    const onAction = () => {
        console.log('User is doing an action');
        localStorage.setItem('lastActiveTime', Date.now());
    };

    const onPrompt = () => {
        setOpen(true);
    };

    const timerConfig = {
        onIdle,
        onActive,
        onAction,
        onPrompt,
        timeout: TIMEOUT,
        throttle: THROTTLE,
        promptBeforeIdle: PROMPT_BEFORE_IDLE,
        crossTab: true,
        syncTimers: SYNC_TIMERS,
        // leaderElection: true,
        disabled: !isLoggedIn || IDLE_TIMEOUT_DURATION === 0
    };

    // console.log('timerConfig', timerConfig);

    const { getRemainingTime, activate } = useIdleTimer(timerConfig);

    const handleStillHere = () => {
        activate();
        setOpen(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, THROTTLE);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        // console.log('remaining', remaining);
        if (remaining * 1000 > PROMPT_BEFORE_IDLE) {
            setOpen(false);
        }
    }, [remaining]);

    useEffect(() => {
        if (isLoggedIn && IDLE_TIMEOUT_DURATION > 0) {
            const lastActiveTime = parseInt(localStorage.getItem('lastActiveTime'), 10);
            console.log('lastActiveTime', lastActiveTime);
            if (lastActiveTime) {
                const idleTimeoutDurations = IDLE_TIMEOUT_DURATION * 60 * 1000;
                if (Date.now() - lastActiveTime > idleTimeoutDurations) {
                    logoutAndReset();
                    console.log('User has been logged out due to inactivity');
                }
            }
        }
    }, [isLoggedIn]);

    return (
        <>
            {children}
            <IdleAlert open={open} handleStillHere={handleStillHere} remaining={remaining} />
        </>
    );
}

export default IdleDetector;

function IdleAlert({ open, handleStillHere, remaining }) {
    return (
        <>
            <Dialog open={open} onClose={handleStillHere} fullWidth maxWidth="xs">
                <DialogTitle id="alert-dialog-title">Are you still here?</DialogTitle>
                <DialogContent>
                    <DialogContentText>You will be logged out in {remaining} seconds</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleStillHere} autoFocus>
                        I&apos;m still here
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
