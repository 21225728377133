import { NOTIFICATION_DURATION } from 'constant';
import { useSnackbar as useNotistack } from 'notistack';

const useSnackbar = () => {
    const { enqueueSnackbar, closeSnackbar } = useNotistack();

    const notify = ({ message, severity, onClose }) => {
        const handleClose = () => {
            onClose?.();
            closeSnackbar();
        };

        if (typeof message === 'string') {
            enqueueSnackbar(message, { variant: severity, autoHideDuration: NOTIFICATION_DURATION, onClose: handleClose });
        } else if (Array.isArray(message)) {
            message?.forEach((item) => {
                if (typeof item === 'string') {
                    enqueueSnackbar(item, { variant: severity, autoHideDuration: NOTIFICATION_DURATION, onClose: handleClose });
                } else {
                    enqueueSnackbar('Message not found', {
                        variant: item.severity,
                        autoHideDuration: NOTIFICATION_DURATION,
                        onClose: handleClose
                    });
                }
            });
        }
    };

    const notifySuccess = ({ message }) => {
        notify({ message, severity: 'success' });
    };

    const notifyError = ({ error }) => {
        if (error.data) {
            notify({ message: error.data.message, severity: 'error' });
        } else {
            notify({
                message: 'Something went wrong',
                severity: 'error'
            });
        }
    };

    return { notify, notifySuccess, notifyError };
};

export default useSnackbar;
