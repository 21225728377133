import React, { useRef, useEffect } from 'react';
import { useSendErrorLogMailMutation } from 'store/services/core';

function SendErrorLog({ hasError, errorInfo, renderCounter }) {
    const [sendErrorLogMail, { isLoading }] = useSendErrorLogMailMutation();

    const timeout = useRef(null);

    useEffect(() => {
        if (hasError && errorInfo) {
            const triggerErrorLog = async () => {
                try {
                    const payload = {
                        error: errorInfo
                    };
                    sendErrorLogMail(payload);
                } catch (error) {
                    console.log({ error });
                }
            };

            timeout.current = setTimeout(() => {
                triggerErrorLog();
            }, 1000);
        }

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [hasError, errorInfo]);

    return null;
}

export default SendErrorLog;
