/* eslint-disable */
import React, { useRef, forwardRef, useState, useEffect } from 'react';
import { TextField, Paper, Autocomplete, Checkbox, InputAdornment, IconButton } from '@mui/material';
import { DatePicker, MobileDatePicker, DateTimePicker, MobileDateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { GLOBAL_DATE_FORMAT } from 'constant';
import EventIcon from '@mui/icons-material/Event';

import dayjs from 'dayjs';

// import { styled } from '@mui/styles';

export const AspyreTextField = forwardRef((props, ref) => {
    const { value, onChange, debounce = true, debounceTime = 500, ...rest } = props;

    const timeout = useRef(null);
    const [debounceValue, setDebounceValue] = useState(value || '');

    useEffect(() => {
        setDebounceValue(value || '');
    }, [value]);

    const onDebounceChange = (e) => {
        const newValue = e.target.value;

        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        if (debounce) {
            setDebounceValue(newValue);

            timeout.current = setTimeout(() => {
                onChange(e);
            }, debounceTime);
        } else {
            onChange(e);
        }
    };

    return <TextField ref={ref} value={debounceValue} onChange={onDebounceChange} {...rest} />;
});

export const AspyreDatePicker = (props) => {
    const { fullWidth, value, onChange, minDate, maxDate, renderInput, size, ...rest } = props;

    const renderInputProps = renderInput ? renderInput(rest).props : {};

    return (
        <DatePicker
            inputFormat={GLOBAL_DATE_FORMAT}
            value={value ? dayjs(value) : null}
            onChange={onChange}
            minDate={minDate ? dayjs(minDate) : null}
            maxDate={maxDate ? dayjs(maxDate) : null}
            renderInput={(props) => <TextField {...props} />}
            slotProps={{
                textField: {
                    ...renderInputProps,
                    ...(size && { size }),
                    ...(fullWidth && { fullWidth: true }),
                    inputProps: {
                        spellCheck: 'false'
                    }
                }
            }}
            {...rest}
        />
    );
};

export const AspyreMobileDatePicker = (props) => {
    const { fullWidth, value, onChange, minDate, maxDate, renderInput, size, ...rest } = props;

    const renderInputProps = renderInput ? renderInput(rest).props : {};

    return (
        <MobileDatePicker
            inputFormat={GLOBAL_DATE_FORMAT}
            value={value ? dayjs(value) : null}
            onChange={onChange}
            closeOnSelect={true}
            minDate={minDate ? dayjs(minDate) : null}
            maxDate={maxDate ? dayjs(maxDate) : null}
            renderInput={(props) => <TextField {...props} />}
            slotProps={{
                textField: {
                    ...renderInputProps,
                    ...(size && { size }),
                    ...(fullWidth && { fullWidth: true }),
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position="end">
                                <EventIcon />
                            </InputAdornment>
                        )
                    }
                },
                layout: {
                    sx: {
                        display: 'flex',
                        flexDirection: 'column'
                    }
                },
                toolbar: { hidden: true },
                actionBar: {
                    actions: ['clear', 'cancel']
                }
            }}
            {...rest}
        />
    );
};

export const AspyreTimePicker = (props) => {
    const { value, onChange, minTime, maxTime, renderInput, ...rest } = props;

    const renderInputProps = renderInput ? renderInput(rest).props : {};

    return (
        <TimePicker
            closeOnSelect={false}
            format="hh:mm A"
            timeSteps={{ minutes: 1 }}
            value={value ? dayjs(value) : null}
            onChange={onChange}
            minTime={minTime ? dayjs(minTime) : null}
            maxTime={maxTime ? dayjs(maxTime) : null}
            renderInput={(props) => <TextField {...props} />}
            slotProps={{
                textField: {
                    ...renderInputProps,
                    inputProps: {
                        spellCheck: 'false'
                    }
                }
            }}
            {...rest}
        />
    );
};

export const AspyreDateTimePicker = (props) => {
    const { value, onChange, minDateTime, maxDateTime, renderInput, slotProps, ...rest } = props;

    const renderInputProps = renderInput ? renderInput(rest).props : {};

    return (
        <DateTimePicker
            popperProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'left' } }}
            closeOnSelect={false}
            inputFormat={`${GLOBAL_DATE_FORMAT} hh:mm A`}
            timeSteps={{ minutes: 1 }}
            value={value ? dayjs(value) : null}
            onChange={onChange}
            minDateTime={minDateTime ? dayjs(minDateTime) : null}
            maxDateTime={maxDateTime ? dayjs(maxDateTime) : null}
            renderInput={(props) => <TextField {...props} />}
            slotProps={{
                textField: {
                    ...renderInputProps,
                    inputProps: {
                        spellCheck: 'false'
                    }
                },
                // popper: { placement: 'top-start' }
                ...slotProps
            }}
            {...rest}
        />
    );
};

export const AspyreMobileDateTimePicker = (props) => {
    const { value, onChange, minDateTime, maxDateTime, renderInput, ...rest } = props;

    const renderInputProps = renderInput ? renderInput(rest).props : {};

    return (
        <MobileDateTimePicker
            inputFormat={`${GLOBAL_DATE_FORMAT} hh:mm A`}
            value={value ? dayjs(value) : null}
            onChange={onChange}
            minDateTime={minDateTime ? dayjs(minDateTime) : null}
            maxDateTime={maxDateTime ? dayjs(maxDateTime) : null}
            renderInput={(props) => <TextField {...props} />}
            slotProps={{
                textField: {
                    ...renderInputProps,
                    inputProps: {
                        spellCheck: 'false'
                    }
                }
            }}
            {...rest}
        />
    );
};
