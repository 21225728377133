import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'constant';
// import { changeDrawerType, openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';

// assets
import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, drawerType }) => ({
    ...theme.typography.mainContent,
    backgroundColor: theme.palette.background.light,

    [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        width: '100%'
    },
    [theme.breakpoints.up('md')]: {
        ...(drawerType === 'permanent' && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter
            })
        }),

        ...(drawerType === 'temporary' && {
            marginLeft: 72,
            width: `calc(100% - ${72}px)`,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter
            })
        })
    }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const location = useLocation();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const { drawerType, drawerOpen } = useSelector((state) => state.menu);
    const { container, layout } = useConfig();

    // useEffect(() => {
    //     dispatch(changeDrawerType('temporary'));
    //     dispatch(openDrawer(false));
    // }, [location.pathname]);

    const header = useMemo(
        () => (
            <Toolbar sx={{ p: '16px' }}>
                <Header />
            </Toolbar>
        ),
        [layout, matchDownMd]
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="background"
                elevation={0}
                sx={{
                    zIndex: theme.zIndex.drawer - 1,
                    backgroundColor: '#261A48',
                    transition: drawerOpen ? theme.transitions.create('width') : 'none'
                }}
            >
                {header}
            </AppBar>

            {/* drawer */}
            <Sidebar />

            {/* main content */}
            <Main
                theme={theme}
                open={drawerOpen}
                layout={layout}
                drawerType={drawerType}
                style={{ minHeight: 'unset', marginBottom: '10px' }}
            >
                <Container
                    maxWidth={container ? 'lg' : false}
                    {...(!container && { sx: { px: { xs: 0 } } })}
                    style={{ maxHeight: '100%', overflow: 'scroll' }}
                >
                    <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                    <Outlet />
                </Container>
            </Main>
        </Box>
    );
};

export default MainLayout;
