import { useRef, useEffect, memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
// import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from '../MenuList';
import LogoSection from '../LogoSection';
import MiniDrawerStyled from './MiniDrawerStyled';

import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'constant';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
    const sidebarRef = useRef(null);
    const theme = useTheme();

    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const { drawerOpen, drawerType } = useSelector((state) => state.menu);

    // const { drawerType } = useConfig();

    const logo = useMemo(
        () => (
            <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                <LogoSection />
            </Box>
        ),
        []
    );

    const drawer = useMemo(
        () => (
            <Box sx={{ height: '100%', mt: matchUpMd ? 11 : 0, px: drawerOpen ? '16px' : 0, overflowY: 'auto' }}>
                <MenuList lastItem={null} />
            </Box>
        ),

        [matchUpMd, drawerOpen, drawerType]
    );

    const handleMouseEnter = () => {
        if (drawerType === 'temporary') {
            dispatch(openDrawer(true));
        }
    };

    const handleMouseLeave = () => {
        if (drawerType === 'temporary') {
            dispatch(openDrawer(false));
        }
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const mainContainer = document.querySelector('main');

        const closeSidebar = () => {
            dispatch(openDrawer(false));
        };

        if (drawerOpen && drawerType === 'temporary') {
            mainContainer.addEventListener('mousemove', closeSidebar);
        }

        return () => {
            mainContainer.removeEventListener('mousemove', closeSidebar);
        };
    }, [drawerOpen, drawerType]);

    return (
        <Box
            ref={sidebarRef}
            component="nav"
            sx={{
                flexShrink: { md: 0 },
                position: 'absolute',
                top: 0,
                left: 0,
                width: matchUpMd ? drawerWidth : 'auto'
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {matchDownMd ? (
                // responsive drawer
                <Drawer
                    variant={matchUpMd ? 'persistent' : 'temporary'}
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                        '& .MuiDrawer-paper': {
                            mt: matchDownMd ? 0 : 11,
                            zIndex: 1099,
                            width: drawerWidth,
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none'
                        }
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    {matchDownMd && logo}
                    {drawer}
                </Drawer>
            ) : (
                // default drawer
                <MiniDrawerStyled variant="permanent" open={drawerOpen}>
                    {drawer}
                </MiniDrawerStyled>
            )}
        </Box>
    );
};

export default memo(Sidebar);
